import { browser } from "$app/environment";

/**
 * Joins class strings together.
 */
export function classes(...args: (string | false | undefined)[]): string {
	return args.filter(e => !!e).map((e) => (e as string).trim()).join(' ');
}

export function styles(...args: string[]): string {
	return args.map((e) => e.trim()).join(' ');
}

export function nonNullArray<E>(item: (E | null | undefined)[] | null | undefined): E[] {
	return item?.filter(item => !!item) as E[] ?? [];
}

export function checkCheckboxChecked() {
	return (value: boolean) => ({
		valid: value,
		name: 'is_not_accepted'
	});
}

function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds: number, showZeroHours = false): string {
	let seconds = Math.floor(milliseconds / 1000);
	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);

	seconds = seconds % 60;
	minutes = minutes % 60;

	// 👇️ If you don't want to roll hours over, e.g. 24 to 00
	// 👇️ comment (or remove) the line below
	// commenting next line gets you `24:00:00` instead of `00:00:00`
	// or `36:15:31` instead of `12:15:31`, etc.
	hours = hours % 24;

	if (showZeroHours) {
		return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
	} else {
		return `${hours ? padTo2Digits(hours) + ':' : ''}${padTo2Digits(minutes)}:${padTo2Digits(
			seconds
		)}`;
	}
}

export function scrollIntoView(target: string, additionalOffset = -28) {
	const NAVBAR_OFFSET = -64;
	const element = document.querySelector(target);
	if (!element) return;
	const offset = NAVBAR_OFFSET + additionalOffset;
	const y = element.getBoundingClientRect().top + window.scrollY + offset;
	window.scrollTo({ top: y, behavior: 'smooth' });
}

export function dateToDateString(
	date: Date,
	options?: Partial<Intl.DateTimeFormatOptions>
): string {
	if (!date) {
		return '';
	}

	date = new Date(date);

	// formatMatcher?: "basic" | "best fit" | "best fit" | undefined;
	// dateStyle?: "full" | "long" | "medium" | "short" | undefined;
	// timeStyle?: "full" | "long" | "medium" | "short" | undefined;
	// dayPeriod?: "narrow" | "short" | "long" | undefined;
	// fractionalSecondDigits?: 1 | 2 | 3 | undefined;

	const _options: Intl.DateTimeFormatOptions = {
		...{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
		...options
	};

	return date.toLocaleDateString('en-US', _options);
}

export const debounce = (func: () => void, delay: number) => {
	let timer: NodeJS.Timeout;

	return function () {
		// @ts-ignore
		const context = this;
		const args = arguments as any;
		clearTimeout(timer);
		timer = setTimeout(() => func.apply(context, args), delay);
	};
};

export const isSafari = () => {
	if (!browser) return;

	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}